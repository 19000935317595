import { Admin } from '../admins';

export interface AuthState {
  accessToken: string | null;
  admin: Admin | null;
}

const adminInitialState: AuthState = {
  accessToken: null,
  admin: null,
};

export default adminInitialState;
