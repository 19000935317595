import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithRefresh } from '@store/base-query';

const baseApi = createApi({
  baseQuery: baseQueryWithRefresh,
  reducerPath: 'base',
  tagTypes: [
    'Admins',
    'Regions',
    'Cities',
    'Doctors',
    'Patients',
    'RehabilitationCards',
    'Tips',
    'TeamInvites',
    'Specialties',
    'MedicalInstitutions',
    'ICDDiagnoses',
    'DemoScales',
    'StopSignals',
    'Barriers',
    'Scales',
    'ScalePositions',
    'Questions',
    'Ads',
    'NotificationTemplates',
    'Feedbacks',
    'AppInfoBlocks',
    'AppInfoSections',
  ],
  endpoints: () => ({}),
});

export default baseApi;
