import { combineReducers } from '@reduxjs/toolkit';

import layout from '../features/layout';
import auth from '../features/auth';
import authApi from '../features/auth/auth.api';
import baseApi from '@store/features/base.api';

const rootReducer = combineReducers({
  layout,
  auth,
  [authApi.reducerPath]: authApi.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

export default rootReducer;
