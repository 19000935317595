import themeConfig from '@configs/theme-config';

export interface LayoutState {
  isRTL: boolean;
  isMenuCollapsed: boolean;
  isMenuHidden: boolean;
  contentWidth: 'full' | 'boxed';
}

const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem('menuCollapsed');
  //** Parse stored json or if none return initialValue
  return item ? !!JSON.parse(item) : themeConfig.layout.menu.isCollapsed;
};

const layoutInitialState: LayoutState = {
  isRTL: themeConfig.layout.isRTL,
  isMenuCollapsed: initialMenuCollapsed(),
  isMenuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
};

export default layoutInitialState;
