import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import authInitialState from './auth.initial-state';
import authApi from './auth.api';
import AuthResponse from './interfaces/auth-response.interface';
import { RootState } from '../../store-config/store';

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    refreshTokens: (state, { payload }: PayloadAction<AuthResponse>) => {
      state.accessToken = payload.accessToken;
      state.admin = payload.admin;
    },
    loggedOut: (state) => {
      state.accessToken = null;
      state.admin = null;
    },
    accountInfoUpdated: (state, { payload }: PayloadAction<AuthResponse['admin']>) => {
      state.admin = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
        state.accessToken = payload.accessToken;
        state.admin = payload.admin;
      })
      .addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
        state.accessToken = null;
        state.admin = null;
      });
  },
});

export const { refreshTokens, loggedOut, accountInfoUpdated } = authSlice.actions;

export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectAdmin = (state: RootState) => state.auth.admin;
export const selectIsAuthenticated = (state: RootState) => !!state.auth.accessToken;

export default authSlice.reducer;
