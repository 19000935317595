import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import layoutInitialState from './layout.initial-state';
import { RootState } from '../../store-config/store';

const layoutSlice = createSlice({
  name: 'layout',
  initialState: layoutInitialState,
  reducers: {
    handleContentWidth: (state, action: PayloadAction<'full' | 'boxed'>) => {
      state.contentWidth = action.payload;
    },
    handleMenuCollapsed: (state, action: PayloadAction<boolean>) => {
      state.isMenuCollapsed = action.payload;
    },
    handleMenuHidden: (state, action: PayloadAction<boolean>) => {
      state.isMenuHidden = action.payload;
    },
    handleRTL: (state, action: PayloadAction<boolean>) => {
      state.isRTL = action.payload;
    },
  },
});

export const { handleContentWidth, handleMenuCollapsed, handleMenuHidden, handleRTL } =
  layoutSlice.actions;

export const selectContentWidth = (state: RootState) => state.layout.contentWidth;
export const selectIsMenuCollapsed = (state: RootState) => state.layout.isMenuCollapsed;
export const selectIsMenuHidden = (state: RootState) => state.layout.isMenuHidden;
export const selectIsRTL = (state: RootState) => state.layout.isRTL;

export default layoutSlice.reducer;
